import { css, cx } from "@linaria/core";
import { Link } from "gatsby";

import { ApiProvider, QueryProvider, createApi } from "~/api";
import {
  ErrorState,
  LoadingState,
  OpportunityContactUsBanner,
} from "~/components";
import { QUERYSTRING } from "~/constants";
import { Routes } from "~/models";
import { Hero, Layout, Meta, Section } from "~/ui";
import { getAreasOfInterestCards, useAreasOfInterest } from "~/utils";

export default function Opportunities(): JSX.Element {
  const api = createApi(process.env.GATSBY_PUBLIC_ID || "");

  return (
    <ApiProvider api={api}>
      <QueryProvider>
        <Layout>
          <Hero />
          <Section bgColor="offWhite">
            <div className="row justify-content-center">
              <div className="col-12 col-md-10">
                <div
                  className={cx(
                    "bg-white mb-3",
                    css`
                      margin-top: -8rem;
                      padding: 2.5rem 3rem;
                    `
                  )}
                >
                  <h2 className="h3 text-secondary fw-semibold text-center">
                    What interests you?
                  </h2>
                  <p className="lead">
                    Volunteering is an amazing way to create meaningful impact
                    in society while having new experiences and building skills
                    for your future. There are a lot of roles out there, so we
                    suggest starting your search with an issue that you are
                    passionate about by clicking on the different options below.{" "}
                  </p>
                  <p className="lead">
                    Before you do a deep dive into researching these roles,
                    ensure that you have completed the learning modules on our{" "}
                    <Link to={Routes.GettingReady}>Getting Ready</Link> page, so
                    you have all the knowledge you need to kick start your
                    volunteer experience.
                  </p>
                </div>
              </div>
            </div>
            <AreasOfInterestSection />
          </Section>
          <OpportunityContactUsBanner />
        </Layout>
      </QueryProvider>
    </ApiProvider>
  );
}

export const Head = () => {
  return (
    <Meta
      title="Opportunities"
      description="Finding your youth volunteering opportunities."
      url={Routes.Opportunities}
    />
  );
};

const AreasOfInterestSection = () => {
  const { isLoading, isError, data } = useAreasOfInterest();

  if (isLoading) {
    return <LoadingState />;
  }

  if (isError || !data) {
    return <ErrorState />;
  }

  const areaOfInterestCards = getAreasOfInterestCards(data);

  return (
    <div className="row">
      {areaOfInterestCards.map((n, idx) => (
        <div
          key={idx}
          className={cx(
            "opp-category-cards",
            "col-12 col-md-6 mb-4",
            idx % 6 === 3 ? "col-lg-8 extended-card" : "col-lg-4"
          )}
        >
          <article className="shadow-sm">
            <span className="__icon">{n.icon}</span>
            <div className="__img-wrapper">
              <img
                src={n.backgroundImage}
                alt={n.alt}
                className={cx(
                  "img-fluid object-fit-cover w-100 h-100",
                  ["Animal Welfare", "Arts & Entertainment"].includes(n.name) &&
                    css`
                      object-position: top;
                    `
                )}
              />
            </div>
            <div className="__text-wrapper">
              <h3 className="h5 fw-normal mb-2">{n.name}</h3>
              <small className="d-block mb-3 text-muted">{n.description}</small>
              <div className="d-flex flex-column flex-lg-row justify-content-between align-items-lg-center mt-auto">
                <Link
                  to={`${Routes.OpportunityList}?${QUERYSTRING.AREAS_OF_INTEREST}=${n.id}`}
                  className="btn btn-sm ms-lg-auto mt-5 mt-lg-0 stretched-link"
                >
                  View opportunities
                </Link>
              </div>
            </div>
          </article>
        </div>
      ))}
    </div>
  );
};
